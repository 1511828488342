html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.upload-file-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav-link {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.nav-link.active {
  border-bottom-color: var(--chakra-colors-yellow-300);
}
.nav-link-tablet {
  border-left: 5px solid transparent;
}
.nav-link-tablet.active {
  border-left-color: var(--chakra-colors-yellow-300);
  background-color: #F6E05E20;
}
.nav-links-tablet {
  transform: translateX(-100%);
  transition: 0.2s ease;
}
.nav-links-tablet.active {
  transform: translateX(0);
}

.modely-date-picker .rdp-day_selected,
.modely-date-picker .rdp-day_selected:focus-visible,
.modely-date-picker .rdp-day_selected:hover {
  background-color: var(--chakra-colors-primary-500);
}
.modely-date-picker.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--chakra-colors-primary-100);
}

.inspection-sheet-input-box::before {
  content: '';
  position: absolute;
  background-color: green;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

table.striped tr:nth-of-type(odd) td {
  background: var(--chakra-colors-secondary-50)
}

/* Blinking animation */
@keyframes blinking_animation_primary {
  0% {
    background-color: var(--chakra-colors-primary-400);
  }
  50% {
    background-color: var(--chakra-colors-primary-800);
  }
  60% {
    background-color: var(--chakra-colors-primary-400);
  }
  100% {
    background-color: var(--chakra-colors-primary-800);
  }
}
.animation-blinking-primary {
  animation: blinking_animation_primary 1s linear;
}
