body.no-overflow {
  overflow: hidden;
  overscroll-behavior: none;
}

.editor {
  cursor: default;
}

.editor-clickable-item {
  cursor: pointer;
}

.editor * {
  user-select: none;
}

.editor *:focus {
  outline: 0;
  box-shadow: unset !important;
}

.editor-main-canvas.tooling-move {
  cursor: url('/src/assets/imgs/cursor-move.png'), auto;
}

.editor-main-canvas.tooling-crosshair {
  cursor: url('/src/assets/imgs/cursor-cross.png') 10 10, crosshair;
}

.editor .chakra-popover__arrow {
  background: white !important;
}

.editor *:not(canvas) {
  touch-action: pan-y;
}

.editor ::-webkit-scrollbar {
  width: 2px;
}

.editor ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.editor ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--chakra-colors-gray-600);
}

.canvas-tooltip {
  background-color: var(--chakra-colors-gray-700);
  color: var(--chakra-colors-whiteAlpha-900);
}

.canvas-tooltip .chakra-tooltip__arrow {
  background: var(--chakra-colors-gray-700) !important;
  box-shadow: -1px 1px 1px 0 var(--chakra-colors-gray-700) !important;
}

.editor .comment-icon.moving svg .content {
  fill: var(--chakra-colors-yellow-500);
}

.red #point {
  fill: red;
}

.green #point {
  fill: #00cc00;
}

.blue #point {
  fill: #0077ff;
}
